let head  = document.getElementsByTagName('head')[0];
let link  = document.createElement('link');
link.rel  = 'stylesheet';
link.type = 'text/css';
link.href = '/likesandcomments/css/likesandcomments.css';
link.media = 'all';
head.appendChild(link);

let postsElements = $('.post-like-and-comment-data');
let requestData = [];
let likesData = JSON.parse(window.localStorage.getItem('likes-data'));
if(!likesData){
  likesData = {};
}

postsElements.each(function(index, postElement){
  requestData.push(postElement.dataset.postId);
});

if(requestData.length > 0){

  let formData = {
    post_ids : JSON.stringify(requestData)
  };

  $.post('/likesandcomments/api/get-likes-and-comments-count', formData).done(function(data){

    postsElements.each(function(index, postElement){

      let postId = postElement.dataset.postId;

      if(postId in data){

        let postData = data[postId];

        if(postId in likesData){
          $(postElement).find('.like-button')
          .attr('data-liked', 'true')
          .attr('data-like-key', likesData.like_key);
        }

        $(postElement).find('span.likes-count').html(postData.likes_count);
        //$(postElement).find('span.comments-count').html(postData.comments_count);
        $(postElement).show();
      }
    });
  })
  .fail(function(){

  })
  .always(function(){

  });

  $(document).on('click', '.like-button', function(event){


    let $button = $(event.target);

    if($button.attr('data-processing') == 'true'){
      return;
    }

    $button.attr('data-processing', 'true');
    $button.css({opacity:0.2});

    let $postData = $button.closest('.post-like-and-comment-data');
    let postId = $postData.data('post-id');
    let likeState = (postId in likesData);

    let formData = {
      post_id : postId,
      like_key : (likesData[postId] || ''),
      action : !likeState
    };

    $.post('/likesandcomments/api/like-post', formData)
    .done(function(data){

      if(data.action == 'true'){ //Add like
        likesData[postId] = data.like_key;
      }else{ // Remove like
        delete likesData[postId];
      }
      $postData.find('span.likes-count').html(data.likes_count);
      $postData.find('.like-button')
      .attr('data-liked', data.action);
      localStorage.setItem('likes-data', JSON.stringify(likesData));
    })
    .fail(function(){

    })
    .always(function(){
      $button.removeAttr('data-processing');
      $button.css({opacity:''});
    });

    $button.attr('data-liked', 'true');
  });
}

$(document).ready(function(){

  let commentsButtonElements = $('.comments-button');
  let commentPaths = [];
  commentsButtonElements.map(function(index, element){commentPaths.push(element.dataset.href);});

  let url = window.location.href;
  let urlParts = url.split("/");
  let urlProtocol = urlParts[0];
  let urlDomain = urlParts[2];

  let formData = { domain : urlDomain, paths:commentPaths, count_deleted: false };
  let postUrl = urlProtocol+'//commento.'+urlDomain+'/api/comment/count'


  $.post(postUrl, JSON.stringify(formData))
  .done(function(data){

    let commentCounts = JSON.parse(data).commentCounts;
    for(let id in commentCounts){
      let count = commentCounts[id];
      $('.comments-button[data-href="'+id+'"] .comments-count').html(count);
    }
  });
});

$(document).on('click', '.comments-button', function(event){

  let href = this.dataset.href+'#commento';
  window.location.href = href;
});
